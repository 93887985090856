import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {IDeliveryItem} from 'app/blocks/model/delivery-item.model';
import {IProduct} from 'app/blocks/model/product.model';

export interface IExecuteRecipeDTO {
    recipe: IRecipe;
    noOfExecutions: number;
    warehouseId: number;
    recipeExecutionItems: any;
    updateProductCost: boolean;
}

export class ExecuteRecipeDTO implements IExecuteRecipeDTO {
    public recipe: IRecipe;
    public noOfExecutions: number;
    public warehouseId: number;
    public recipeExecutionItems: any;
    public updateProductCost: boolean;
    constructor(recipe?: IRecipe) {
        if (recipe) {
            this.recipe = recipe;
        } else {
            this.recipe = null;
        }
        this.noOfExecutions = null;
        this.warehouseId = null;
    }
}

export class RecipeItem {
    quantity?: number;
    product?: IProduct;
    productType: string;
}

export interface IRecipe extends IBaseEntity {
    id?: number;
    name?: string;
    description?: string;
    createdBy?: string;
    lastModifiedBy?: string;
    createdOn?: any;
    lastModifiedOn?: any;
    items?: RecipeItem[];
}

export class Recipe extends BaseEntity implements IRecipe {
    public id?: number;
    public name?: string;
    public description?: string;
    public createdBy?: string;
    public lastModifiedBy?: string;
    public createdOn?: any;
    public lastModifiedOn?: any;
    public items: RecipeItem[];
    constructor(recipe?) {
        super();
        if (recipe) {
            this.id = recipe.id;
            this.name = recipe.name;
            this.description = recipe.description;
            this.createdBy = recipe.createdBy;
            this.createdOn = recipe.createdOn;
            this.lastModifiedBy = recipe.lastModifiedBy;
            this.lastModifiedOn = recipe.lastModifiedOn;
            this.items = recipe.items || [];
        } else {
        }
    }

    get discriminator(): any {
        return this.name;
    }

    clone(): Recipe {
        return new Recipe(this);
    }
}
